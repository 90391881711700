import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

import "./Home.css";

const Home = () => {

    const navigate = useNavigate();

    return (
        <div className="home-section">

            <Header lightOrDark="light" />

            <div className="home-container">

                <div>
                    <p className="main-content-text">
                        Open-source <b>data warehouse</b> for unstructured data
                    </p>
                    <p className="main-content-sub-text">
                        D* creates a seamless connection between data sources and LLMs, enabling users to query, analyze, and run reports over unstructured data.
                    </p>
                    <div className="learn-more-button-container" onClick={() => navigate("/blogs")}>
                        <p className="learn-more-text">
                            Learn more
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default Home;