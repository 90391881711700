import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../screens/Home';
import Blogs from '../screens/Blogs';
import EmbeddingsArticle from '../screens/EmbeddingsArticle';
import ContextArticle from '../screens/ContextArticle';

const MainNavigator = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/embeddings-are-not-all-you-need" element={<EmbeddingsArticle />} />
                <Route path="/solving-the-out-of-context-chunk-problem-for-rag" element={<ContextArticle />} />
            </Routes>
        </BrowserRouter>
    )

}

export default MainNavigator;