import React from 'react';
import { useNavigate } from 'react-router-dom';

import "./Header.css";

const Header = ({ lightOrDark = "light" }) => {

    const navigate = useNavigate();

    return (
        <div className="home-header-row">
            <p className={`d-star-logo-${lightOrDark}`} onClick={() => navigate("/")}>D*</p>
            <p className={`header-blog-text-${lightOrDark}`} onClick={() => navigate("/blogs")}>Blog</p>
        </div>
    )

}

export default Header;